import './disclaimer.css';

import { CustomModalContent } from '../CustomComponents';
import { useDisclaimerContext } from '../../lib/contexts';
import { useState } from 'react';

function Disclaimer() {
  const { disclaimerContext } = useDisclaimerContext();
  const { privacyPolicy, termsOfUse } = disclaimerContext;

  const [isOpen, setIsOpen] = useState({
    privacyPolicy: false,
    optOut: false,
  });
  const openModal = (key) => setIsOpen({ ...isOpen, [key]: true });
  const closeModal = (key) => setIsOpen({ ...isOpen, [key]: false });

  const year = new Date().getFullYear();
  /*const date = new Date().getDate();
  const month = new Date().toLocaleString('default', { month: 'long' });*/

  return (
    <footer id="site-footer">
      <CustomModalContent
        title="Privacy Policy"
        isOpen={isOpen.privacyPolicy}
        onClose={() => closeModal('privacyPolicy')}
        content={privacyPolicy.data?.html}
        isHtml
      />
      <CustomModalContent
        title="Terms Of Use"
        isOpen={isOpen.termsOfUse}
        onClose={() => closeModal('termsOfUse')}
        content={termsOfUse.data?.html}
        isHtml
      />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <label>
              <p>
                *POTENTIALLY LOWER YOUR OVERALL MONTHLY PAYMENTS -{' '}
                <a
                  href="https://www.fool.com/the-ascent/mortgages/refinance-pay-off-debt/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Motley Fool
                </a>
              </p>
              <br />
              <p>
                GovLoanOptions.com provides marketing services for various
                companies involved in mortgage refinance and is not affiliated
                or endorsed by the VA or any other government agency. The
                information you provide to us is provided to these companies in
                real time. If the information you provide to us matches the
                criteria they are seeking, they will contact you directly. In
                many cases we may deploy SMS to you to facilitate an inbound
                call to the service or product provider. We are paid by such
                providers for each consumer they contact directly and/or provide
                services or products. You are not charged for our services. We
                do not guarantee that a provider will accept your request or
                that their products or services will meet your needs. Their
                services and products may or may not be the best product or
                service available on the market. Completing our forms does not
                obligate you to purchase a service or product nor does it
                obligate a product or service provider to provide you with any
                particular service about which you may have inquired. You must
                be a legal resident of the United States to use this Website and
                we specifically prohibit access or use by residents of other
                countries including but not limited to Canadian and European
                Union Member Citizens.{' '}
                <a
                  href="https://www.nmlsconsumeraccess.org"
                  target="_blank"
                  rel="noreferrer"
                >
                  Nationwide Mortgage Licensing System (NMLS) ID # 2721.
                </a>
              </p>
              <ul className="footer-links">
                <li>
                  <a
                    href="http://www.refiexpert.net"
                    target="_blank"
                    rel="noreferrer"
                    id="terms-link"
                  >
                    Licenses and Disclosures
                  </a>
                </li>
              </ul>
            </label>
          </div>
          <div className="col-sm-12">
            <ul className="footer-links">
              <li>
                <span>&copy; {year} GovLoanExperts.com</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <ul className="form-links">
            <li>
              <a
                onClick={() => openModal('privacyPolicy')}
                className="mfp-iframe"
                id="privacy-link"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                onClick={() => openModal('termsOfUse')}
                className="mfp-iframe"
                id="terms-link"
              >
                Terms of Use
              </a>
            </li>
            <li>
              <a
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_DO_NOT_SELL_MY_INFO,
                    '_blank'
                  )
                }
              >
                Do Not Sell My Info
              </a>
            </li>
            <li>
              <a
                href="https://www.folderleafsix.com/o-qkbh-n94-5b4a542ca7830ea75bbb6a1bf7d612f1"
                target="_blank"
                rel="noreferrer"
              >
                Opt Out
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Disclaimer;
