import { createContext, useContext, useState } from 'react';

const defaultState = {
  address: '',
  city: '',
  email: '',
  firstname: '',
  hometype: 'Multi-Family Dwelling Unit',
  lastname: '',
  phone: '',
  estimatedProperty: '$255,001 - $270,000',
  estimatedMortgage: '$195,001 - $210,000',
  creditType: 'Good',
  takecash: 'maybe',
  employmentstatus: 'employed',
  fhaloan: 'no',
  veteran: 'no',
  state: '',
  zip: '',
  step: 1,
};

const FormDataContext = createContext();

export const useFormDataContext = () => useContext(FormDataContext);

export const FormDataProvider = ({ children }) => {
  const [formData, setFormData] = useState(defaultState);

  const updateFormData = (data) => {
    setFormData({ ...formData, ...data });
  };

  const clearStorage = () => setFormData(defaultState);

  const updateStep = (step) => {
    console.log(step, 'step');
    setFormData({
      ...formData,
      step,
    });
  };

  return (
    <FormDataContext.Provider
      value={{
        formData,
        updateFormData,
        updateStep,
        clearStorage,
      }}
    >
      {children}
    </FormDataContext.Provider>
  );
};
