import {
  EMAIL_ERROR_MESSAGE,
  FIRST_NAME_ERROR_MESSAGE,
  LAST_NAME_ERROR_MESSAGE,
  NAME_REGEX,
} from '../../../lib/constants';

import { onlyLettersInput, validateEmail } from '../../../lib/utils';
import { CustomInput } from '../../CustomComponents';
import iconLocked from '../../../assets/img/icon-locked.webp';

import { useFormContext } from 'react-hook-form';
import { useFormDataContext } from '../../../lib/contexts';
import { useRef } from 'react';
import { useScrollToTop } from '../../../lib/hooks';

function FormStep8() {
  const { register, formState, handleSubmit, trigger, clearErrors } =
    useFormContext();
  const { errors } = formState;
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const { updateStep, updateFormData, formData } = useFormDataContext();
  useScrollToTop();
  const handleClickContinue = handleSubmit(async (_data) => {
    const isValid = await trigger(['firstname', 'lastname']);

    if (!isValid) {
      const [firstErrorKey] = Object.keys(errors);
      const errorRefs = { firstname: firstNameRef, lastname: lastNameRef };

      errorRefs[firstErrorKey]?.current?.focus();
    } else {
      updateStep(formData.step + 1);
    }
  });
  return (
    <>
      <a
        onClick={() => {
          clearErrors();
          updateStep(formData.step - 1);
        }}
        className="step-back"
        href
      >
        ← Back
      </a>
      <div className="step active">
        <div className="form-header">
          <h3>Your contact information</h3>
        </div>

        <div className="input-wrap">
          <label>First Name</label>
          <CustomInput
            type="text"
            placeholder="First Name"
            name="firstname"
            onInput={onlyLettersInput}
            register={register}
            onInputChange={updateFormData}
            validation={{
              required: FIRST_NAME_ERROR_MESSAGE,
              pattern: {
                value: NAME_REGEX,
                message: FIRST_NAME_ERROR_MESSAGE,
              },
            }}
          />
        </div>
        <div className="input-wrap">
          <label>Last Name</label>
          <CustomInput
            type="text"
            placeholder="Last Name"
            name="lastname"
            onInput={onlyLettersInput}
            register={register}
            onInputChange={updateFormData}
            validation={{
              required: LAST_NAME_ERROR_MESSAGE,
              pattern: {
                value: NAME_REGEX,
                message: LAST_NAME_ERROR_MESSAGE,
              },
            }}
          />
        </div>
        <div className="input-wrap">
          <label>Email Address</label>
          <CustomInput
            type="text"
            placeholder="Email Address"
            name="email"
            register={register}
            onInputChange={updateFormData}
            validation={{
              required: EMAIL_ERROR_MESSAGE,
              validate: validateEmail,
            }}
          />
        </div>

        <button onClick={handleClickContinue} className="step-button">
          Continue
        </button>
        <div className="step-bottom">
          <img src={iconLocked} alt="Secure form" /> <span>Secure form</span>
        </div>
      </div>
    </>
  );
}

export default FormStep8;
