import { partnerList, privacyPolicyAPI, termsOfUseAPI } from '../constants';

export async function getDisclaimerContent() {
  const apiUrls = [privacyPolicyAPI, termsOfUseAPI, partnerList];

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ sitename: process.env.REACT_APP_SITE_NAME }),
  };

  const requests = apiUrls.map((url) =>
    fetch(url, requestOptions).then((response) => response.json())
  );
  const results = await Promise.all(requests);

  return {
    privacyPolicy: results[0],
    termsOfUse: results[1],
    partnerList: results[2],
  };
}
