import './mainForm.css';
import './steps/formSteps.css';

import { CustomLoader } from '../CustomComponents';
import FirstSection from './steps/FirstSection';
import SecondForm from './SecondForm';
import SecondSection from './SecondSection';
import { useEffect } from 'react';

function MainForm() {
  useEffect(() => {
    //write code
  }, []);
  return (
    <>
      <CustomLoader />
      <FirstSection />
      <SecondSection />
      <SecondForm />
    </>
  );
}

export default MainForm;
