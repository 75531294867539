import './assets/css/app.css';

import {
  CustomPixelHelmet,
  Disclaimer,
  MainForm,
  PageTitle,
  ThankYouAlternative,
  ThankYouLenderList,
  TopBarAndFooter,
} from './components';
import {
  EXIT_PAGE_MESSAGE,
  THANK_YOU_ALTERNATIVE_ROUTE,
  THANK_YOU_LENDER_LIST_ROUTE,
} from './lib/constants';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import {
  useAnura,
  useExitConfirmation,
  useJornaya,
  useTrustedForm,
} from './lib/hooks';

const App = () => {
  useExitConfirmation(EXIT_PAGE_MESSAGE);
  useJornaya();
  useTrustedForm();
  useAnura();

  return (
    <div className="page">
      <PageTitle title={process.env.REACT_APP_TAB_NAME} />
      <CustomPixelHelmet />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <div className="main-content">
                <TopBarAndFooter />
                <MainForm />
                <div>
                  <Disclaimer />
                </div>
              </div>
            }
          />

          <Route
            path={THANK_YOU_LENDER_LIST_ROUTE}
            element={
              <>
                <TopBarAndFooter className="top-bar-and-footer__bottom-gray " />
                <ThankYouLenderList />
              </>
            }
          />

          <Route
            path={THANK_YOU_ALTERNATIVE_ROUTE}
            element={
              <>
                <TopBarAndFooter />
                <ThankYouAlternative />
              </>
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
