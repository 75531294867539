import { handleKeyDown } from '../../lib/utils';
import { useFormContext } from 'react-hook-form';

const CustomInput = ({ register, onInputChange, validation, ...props }) => {
  const { setValue, formState } = useFormContext();
  const { errors } = formState;

  return (
    <input
      {...props}
      onKeyDown={handleKeyDown}
      className={`form-step__input ${
        errors[props.name] && 'form-step__input-error'
      }`}
      autoComplete="off"
      {...register(props.name, validation)}
      onChange={(e) => {
        onInputChange({ [props.name]: e.target.value });
        setValue(props.name, e.target.value);
      }}
    />
  );
};

export default CustomInput;
