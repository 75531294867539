import React, { createContext, useContext, useState } from 'react';

// Create a context
const ZipContext = createContext();

// Create a context provider
export const ZipProvider = ({ children }) => {
  const [zipcodeSubmit, setZipcodeSubmit] = useState(false);

  const updateZipcodeSubmit = (newZipcodeSubmit) => {
    setZipcodeSubmit(newZipcodeSubmit);
  };

  return (
    <ZipContext.Provider value={{ zipcodeSubmit, updateZipcodeSubmit }}>
      {children}
    </ZipContext.Provider>
  );
};
export const useZipContext = () => useContext(ZipContext);
