import { ZIP_CODE_ERROR_MESSAGE, ZIP_CODE_REGEX } from '../../../lib/constants';
import {
  debounceUpdate,
  getErrorMessage,
  handleKeyDown,
  isZipCodeValid,
  onlyNumbersInput,
} from '../../../lib/utils';
import { useFormDataContext, useZipContext } from '../../../lib/contexts';
import { useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useFormContext } from 'react-hook-form';
import { useScrollToTop } from '../../../lib/hooks';
function FormZip() {
  const [isValidatingZip, setIsValidatingZip] = useState(false);
  const {
    register,
    setValue,
    trigger,
    handleSubmit,
    formState,
    setError,
    clearErrors,
  } = useFormContext();
  const { updateFormData, formData } = useFormDataContext();
  const { updateZipcodeSubmit, zipcodeSubmit } = useZipContext();
  const [zipCodeFormStatus, setZipCodeFormStatus] = useState(false);
  const { errors } = formState;
  const zipRef = useRef();

  const handleClickContinue = handleSubmit(async (_data) => {
    const isValid = await trigger(['zip']);

    if (!isValid) {
      const [firstErrorKey] = Object.keys(errors);
      const errorRefs = {
        zip: zipRef,
      };
      setZipCodeFormStatus(false);
      updateZipcodeSubmit(zipCodeFormStatus);
      errorRefs[firstErrorKey]?.current?.focus();
    } else {
      setZipCodeFormStatus(true);
      updateZipcodeSubmit(zipCodeFormStatus);
      clearErrors('zip');
      setValue('city', formData.city);
      updateFormData({ city: formData.city });
      setValue('state', formData.state);
      updateFormData({ state: formData.state });
      setValue('zip', formData.zip);
      updateFormData({ zip: formData.zip });
    }
  });

  const handleUpdateZipCode = async (data) => {
    setIsValidatingZip(true);
    const { error, ...resultData } = await isZipCodeValid(data.zip);
    updateFormData(resultData);

    if (error) {
      setError('zip', { message: ZIP_CODE_ERROR_MESSAGE });
    } else {
      setZipCodeFormStatus(true);
      updateZipcodeSubmit(zipCodeFormStatus);
      console.log(zipCodeFormStatus);
      console.log(zipcodeSubmit);
      clearErrors('zip');
      setValue('city', resultData.city);
      setValue('state', resultData.state);
      setValue('zip', resultData.zip);
    }
    setIsValidatingZip(false);
  };

  useScrollToTop();

  return (
    <div className="form">
      <form className="start-form start-form-top">
        <input
          type="number"
          placeholder="Please enter ZIP code"
          ref={zipRef}
          onKeyDown={handleKeyDown}
          className={`form-step__input ${
            errors.zip && 'form-step__input-error'
          }`}
          maxLength="5"
          inputMode="numeric"
          onInput={onlyNumbersInput}
          autoComplete="off"
          {...register('zip', {
            required: ZIP_CODE_ERROR_MESSAGE,
            pattern: {
              value: ZIP_CODE_REGEX,
            },
            validate: () => {
              if (formData.city === '') return ZIP_CODE_ERROR_MESSAGE;
            },
          })}
          onChange={(e) =>
            debounceUpdate('zip', e.target.value, handleUpdateZipCode)
          }
        />

        <button
          onClick={handleClickContinue}
          id="zip-button"
          className="start-form-submit"
          disabled={Object.keys(errors).length > 0}
        >
          See Savings
        </button>

        {!isEmpty(errors) && (
          <div className="form-step__error-box">{getErrorMessage(errors)}</div>
        )}
      </form>
    </div>
  );
}

export default FormZip;
