import { FormProvider, useForm } from 'react-hook-form';
import FormZip from './steps/FormZip';
import React from 'react';

import icon1 from '../../assets/img/icon-1.webp';
import icon2 from '../../assets/img/icon-2.webp';
import icon3 from '../../assets/img/icon-3.webp';
import icon4 from '../../assets/img/icon-4.webp';
import { useFormDataContext } from '../../lib/contexts/';

function SecondSection() {
  const { formData } = useFormDataContext();
  const methods = useForm({ defaultValues: formData, mode: 'onSubmit' });
  return (
    <section className="why">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <ul className="form-links">
              <li>
                <a
                  href="#advert-disclosure"
                  className="mfp-inline"
                  id="privacy-link"
                >
                  Advertising Disclosures
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 section-top">
            <div className="inner">
              <h2 className="section-title">Why Refinance Your Mortgage?</h2>
              <p>
                These are just a few of the benefits of refinancing your
                mortgage.
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col">
            <div className="inner">
              <img
                src={icon1}
                alt="Get a customized quote."
                className="col-icon"
              />
              <h3>Lower your payment</h3>
              <p>
                Today`&apos;`s rates may be much lower than your current rate.
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col">
            <div className="inner">
              <img
                src={icon2}
                alt="You pay little to nothing up front."
                className="col-icon"
              />
              <h3>Use your home equity to better manage debt</h3>
              <p>
                Consolidate car payments or credit card debt into your mortgage
                at lower rates. Let the equity in your home help you to live a
                stress free life.
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col">
            <div className="inner">
              <img
                src={icon3}
                alt="Lock in low energy rates."
                className="col-icon"
              />
              <h3>Pay off your loan faster</h3>
              <p>
                With lower rates you may be able to switch to a 20 or 15 year
                term without a major payment increase. That means you can pay
                off your home much faster.
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col">
            <div className="inner">
              <img
                src={icon4}
                alt="Lock in low energy rates."
                className="col-icon"
              />
              <h3>Get a low rate for the life of your loan</h3>
              <p>
                Lock in a 30 year fixed rate all time lows. Rest assured your
                rate will not increase for the life of your mortgage loan.
              </p>
            </div>
          </div>
          <div className="col-sm-12">
            <FormProvider {...methods}>
              <FormZip />
            </FormProvider>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SecondSection;
