import React, { createContext, useContext, useEffect, useState } from 'react';

const LocationContext = createContext();

export const useLocation = () => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error('useLocation must be used within a LocationProvider');
  }
  return context;
};

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_GEO_LOCATION_URL)
      .then((response) => response.json())
      .then((data) => {
        setLocation(data.city || 'City information not available');
      })
      .catch((error) => {
        setLocation('Failed to retrieve location information.');
      });
  }, []);

  return (
    <LocationContext.Provider value={{ location }}>
      {children}
    </LocationContext.Provider>
  );
};
