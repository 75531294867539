import './mainForm.css';
import './steps/formSteps.css';

import { FormProvider, useForm } from 'react-hook-form';
import {
  FormStep1,
  FormStep2,
  FormStep3,
  FormStep4,
  FormStep5,
  FormStep6,
  FormStep7,
  FormStep8,
  FormStep9,
} from './steps';
import { getErrorMessage, submitForm } from '../../lib/utils';
import {
  useAppContext,
  useFormDataContext,
  useLendersContext,
  useZipContext,
} from '../../lib/contexts/';

import { CustomLoader } from '../CustomComponents';
import SecondFormDisclaimer from '../Disclaimer/SecondFormDisclaimer';
import isEmpty from 'lodash/isEmpty';
import popupLogo from '../../assets/img/logo-popup.webp';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function SecondForm() {
  const { formData, clearStorage } = useFormDataContext();
  const methods = useForm({ defaultValues: formData, mode: 'onSubmit' });
  const { updateZipcodeSubmit, zipcodeSubmit } = useZipContext();
  const navigate = useNavigate();
  const { setLendersContext } = useLendersContext();
  const { appContext } = useAppContext();
  const { step } = formData;
  const [isLoading, setIsLoading] = useState(false);
  const { formState } = methods;
  const { errors } = formState;

  const handleGlobalSubmit = async (_data) => {
    setIsLoading(true);
    console.log('data', _data);
    const result = await submitForm({ ..._data, ...appContext });

    const lendersContextData = {
      name: `${formData.firstname} ${formData.lastname}`,
      zipCode: formData.zip,
    };

    if (result.status === 'matched') {
      lendersContextData.lenders = result.lenders;
    }

    if (result.pixel) {
      lendersContextData.pixel = result.pixel;
    }

    setLendersContext(lendersContextData);

    const route =
      result.status === 'matched'
        ? '/thank-you/lender-list'
        : '/thank-you/alternative';

    clearStorage();
    navigate(route);
    setIsLoading(false);
  };

  return (
    <>
      <CustomLoader isLoading={isLoading} />
      <div
        id="popup"
        className={zipcodeSubmit === true ? 'popup show' : 'popup hide'}
      >
        <div className="step-form-wrap">
          <div className="form-top clearfix">
            <div className="logo col-1">
              <img src={popupLogo} alt="GovLoanOptions.com" />
            </div>
            <div className="col-2"></div>
            <div className="steps col-3">
              <span>Step &nbsp;</span>
              <span className="current">{step + 1} </span> &nbsp;/ 10
            </div>
          </div>
          <FormProvider {...methods}>
            <form
              id="leadform"
              className="step-form"
              name="leadform"
              onSubmit={methods.handleSubmit(handleGlobalSubmit)}
            >
              <div className="step active" data-step={step + 1}></div>
              <div className="inner">
                {!isEmpty(errors) && (
                  <div className="errors">{getErrorMessage(errors)}</div>
                )}
                {step === 1 && <FormStep1 />}
                {step === 2 && <FormStep2 />}
                {step === 3 && <FormStep3 />}
                {step === 4 && <FormStep4 />}
                {step === 5 && <FormStep5 />}
                {step === 6 && <FormStep6 />}
                {step === 7 && <FormStep7 />}
                {step === 8 && <FormStep8 />}
                {step === 9 && <FormStep9 />}
              </div>
            </form>
          </FormProvider>{' '}
          <div className="form-footer">
            <div className="info">
              ©{' '}
              <span>
                <script type="text/javascript">
                  document.write(new Date().getFullYear())
                </script>
                2024&nbsp;
              </span>
              GovLoanExperts.com{' '}
            </div>
            <div className="">
              <SecondFormDisclaimer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SecondForm;
