import { Controller, useFormContext } from 'react-hook-form';
import { forwardRef, useImperativeHandle, useRef } from 'react';

import MaskedInput from 'react-text-mask';
import { handleKeyDown } from '../../lib/utils';

const MaskedInputWithRef = forwardRef((props, ref) => {
  const inputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.inputElement.focus();
    },
  }));

  return <MaskedInput ref={inputRef} {...props} />;
});

MaskedInputWithRef.displayName = 'MaskedInputWithRef';

function CustomPhoneInput({
  name,
  mask,
  validation = {},
  placeholder = 'Phone',
  ...props
}) {
  const { control, setValue, formState } = useFormContext();
  const { errors } = formState;

  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      render={({ field, ref }) => (
        <MaskedInputWithRef
          {...props}
          {...field}
          onKeyDown={handleKeyDown}
          ref={ref}
          mask={mask}
          placeholder={placeholder}
          className={`form-step__input form-step__input-no-background ${
            errors[name] && 'form-step__input-error'
          }`}
          onChange={(e) => {
            field.onChange(e.target.value);
            setValue(name, e.target.value);
          }}
        />
      )}
    />
  );
}

export default CustomPhoneInput;
