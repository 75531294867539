import './customSubmit.css';

import { CustomModalContent } from '../';
import { useDisclaimerContext } from '../../../lib/contexts';
import { useState } from 'react';

function CustomSubmit() {
  const { disclaimerContext } = useDisclaimerContext();
  const { partnerList, termsOfUse, privacyPolicy } = disclaimerContext;

  const [isOpen, setIsOpen] = useState({
    partnerList: false,
    termsOfUse: false,
    privacyPolicy: false,
  });

  const openModal = (key) => setIsOpen({ ...isOpen, [key]: true });
  const closeModal = (key) => setIsOpen({ ...isOpen, [key]: false });

  return (
    <div className="custom-submit__container">
      <CustomModalContent
        title="Partner List"
        isOpen={isOpen.partnerList}
        onClose={() => closeModal('partnerList')}
        content={partnerList.data?.html}
        isHtml
      />
      <CustomModalContent
        title="Terms Of Use"
        isOpen={isOpen.termsOfUse}
        onClose={() => closeModal('termsOfUse')}
        content={termsOfUse.data?.html}
        isHtml
      />
      <CustomModalContent
        title="Privacy Policy"
        isOpen={isOpen.privacyPolicy}
        onClose={() => closeModal('privacyPolicy')}
        content={privacyPolicy.data?.html}
        isHtml
      />
      <div>
        <label className="info" style={{ color: '#7777777' }}>
          By clicking the button below, you acknowledge, consent, and agree to
          our terms at the bottom of this page.
        </label>
        <button type="submit" id="step-final-submit" className="step-button">
          View My Savings
        </button>
        <br />
        <br />
        <label className="info" style={{ color: '#7777777' }}>
          {
            'By clicking "View My Savings" you electronically agree to share your information with up to 6 '
          }
          <a
            className="custom-submit__button-as-link"
            href="#"
            onClick={(e) => {
              e.stopPropagation();
              openModal('partnerList');
            }}
          >
            partners
          </a>
          {
            ' (possibly including Rocket Mortgage) and provide your prior "written" consent for them to contact you (including through automated means; autodialing, text and pre-recorded messaging) via telephone, mobile device (including SMS and MMS) and/or email, even if your telephone number is currently listed on any state, federal or corporate Do Not Call list and you agree to our '
          }
          <a
            href="#"
            className="custom-submit__button-as-link"
            onClick={(e) => {
              e.stopPropagation();
              openModal('termsOfUse');
            }}
          >
            Terms of Use
          </a>
          {' and '}
          <a
            href="#"
            className="custom-submit__button-as-link"
            onClick={(e) => {
              e.stopPropagation();
              openModal('privacyPolicy');
            }}
          >
            Privacy Policy
          </a>
          &nbsp;You agree that we can share your personal data with third parties,
          such as our mortgage partners, service providers and other affiliates,
          and that we can use this data for marketing and analytics, and to make
          your experience easier. This consent is not a condition of a purchase.
        </label>
      </div>

      <label htmlFor="leadid_tcpa_disclosure" style={{ display: 'none' }}>
        <input type="hidden" id="leadid_tcpa_disclosure" />
        By clicking the button below, you acknowledge, consent, and agree to our
        terms at the bottom of this page. By clicking {'GET YOUR MATCHES'} you
        authorize up to three{' '}
        <a href="#partners-list" className="inline-link">
          partners
        </a>{' '}
        to call you (including through automated means; e.g. autodialing, text
        and pre-recorded messaging) via telephone, mobile device (including SMS
        and MMS) and/or email, at the number you entered above, with offers
        about their products or services, even if your phone number is on any
        national or state {'Do Not Call'} list and you agree to our{' '}
        <a href="#terms-of-use" className="inline-link">
          Terms of Use
        </a>{' '}
        and{' '}
        <a href="#privacy-policy" className="inline-link">
          Privacy Policy
        </a>
        . Message and data rates may apply. Your consent here is not based on a
        condition of purchase.
      </label>
    </div>
  );
}

export default CustomSubmit;
