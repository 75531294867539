export const EXIT_PAGE_MESSAGE =
  'Are you sure you want to leave? There is so much more to explore!';

export const ZIP_CODE_ERROR_MESSAGE = 'Please enter a valid ZIP code';

export const NETWORK_ERROR = 'Network Response was not Ok';

export const RADIO_ERROR_MESSAGE = 'Please select an option';

export const PROJECT_TYPE_ERROR_MESSAGE =
  'Please select a window project option';
export const ESTIMATED_PROPERTY_ERROR_MESSAGE =
  'Please select a estimated property option';
export const ESTIMATED_MORTGAGE_ERROR_MESSAGE =
  'Please select a estimated mortage option';

export const TAKE_CASH_ERROR_MESSAGE = 'Please select a take cash option';
export const EMPLOYMENT_STATUS_ERROR_MESSAGE =
  'Please select a employment status option';
export const FHA_LOAN_ERROR_MESSAGE = 'Please select a FHA loan option';
export const VETERAN_ERROR_MESSAGE = 'Please select a veteran option';
export const WINDOWS_COUNT_ERROR_MESSAGE =
  'Please select a window count option';

export const FIRST_NAME_ERROR_MESSAGE = 'Please enter first name';

export const LAST_NAME_ERROR_MESSAGE = 'Please enter last name';

export const ADDRESS_ERROR_MESSAGE = 'Please enter Street Address';

export const ADDRESS_PATTERN_ERROR_MESSAGE =
  'Please do not put your email address in this field';

export const CITY_ERROR_MESSAGE = 'Please enter a city';

export const STATE_ERROR_MESSAGE = 'Please select a State';

export const EMAIL_ERROR_MESSAGE = 'Please enter a valid Email';

export const EMAIL_DOMAIN_ERROR_MESSAGE = 'Please verify your email domain';

export const PHONE_ERROR_MESSAGE =
  'Please enter a valid phone number in the format (xxx) xxx-xxxx';

export const CONSECUTIVE_PHONE_NUMBER_ERROR_MESSAGE =
  'Please input a valid phone number';
