import { HOMETYPE_OPTIONS, RADIO_ERROR_MESSAGE } from '../../../lib/constants';
import { useFormDataContext, useZipContext } from '../../../lib/contexts';
import { useRef, useState } from 'react';
import iconLocked from '../../../assets/img/icon-locked.webp';
import { useFormContext } from 'react-hook-form';
import { useScrollToTop } from '../../../lib/hooks';
function FormStep1() {
  const { register, setValue, trigger, handleSubmit, formState } =
    useFormContext();
  const { updateStep, updateFormData, formData } = useFormDataContext();
  const [isClassRemoved, setClassRemoved] = useState(false);
  const [zipCodeFormStatus, setZipCodeFormStatus] = useState(false);
  const { updateZipcodeSubmit, zipcodeSubmit } = useZipContext();
  const { errors } = formState;
  const hometypeRef = useRef();

  const handleClick = (event) => {
    event.stopPropagation();
    if (event.target.type !== 'radio') {
      const input = event.currentTarget.querySelector('input[type="radio"]');
      input.click();
    }
  };
  const disableForm = () => {
    setClassRemoved(true);
    document.getElementById('popup').classList.remove('popup');
    document.getElementById('popup').classList.remove('show');
    setZipCodeFormStatus(false);
    updateZipcodeSubmit(zipCodeFormStatus);
    console.log('zipcodeSubmit', zipcodeSubmit);
  };
  const handleClickContinue = handleSubmit(async (_data) => {
    console.log(formData);
    const isValid = await trigger(['hometype', 'city', 'state', 'zip']);

    if (!isValid) {
      const [firstErrorKey] = Object.keys(errors);
      const errorRefs = {
        hometype: hometypeRef,
      };

      errorRefs[firstErrorKey]?.current?.focus();
    } else {
      updateStep(formData.step + 1);
    }
  });
  const year = new Date().getFullYear();
  useScrollToTop();

  return (
    <>
      {formData.step > 1 ? (
        <a
          onClick={() => {
            updateStep(formData.step - 1);
          }}
          className="step-back"
          href
        >
          ← Back
        </a>
      ) : (
        <a
          onClick={() => {
            disableForm();
          }}
          className="step-back"
          href
        >
          ← Back
        </a>
      )}
      <h3 className="step-title">
        <span className="city-title-popup">{formData.city}</span> homeowners may
        qualify for new programs in <span>{year}</span>. Confirm your home type
        below:
      </h3>
      <ul className="radio-list">
        {HOMETYPE_OPTIONS.map(
          ({ id, value, textOption, registerLabel }, index) => (
            <li
              key={`${id}-${index}`}
              className={`radio-list-item ${
                errors[registerLabel] && 'form-step__error'
              }`}
              onClick={handleClick}
            >
              <label className="radio-button">
                <input
                  type="radio"
                  value={value}
                  id={id}
                  ref={hometypeRef}
                  {...register(registerLabel, { required: true })}
                  onChange={(e) => {
                    updateFormData({ hometype: e.target.value });
                    setValue(registerLabel, e.target.value);
                  }}
                />
                <span htmlFor={id}>{textOption}</span>
                <div className="radiobutton"></div>
              </label>
            </li>
          )
        )}
        {errors.hometype && (
          <div className="form-step__error-message">{RADIO_ERROR_MESSAGE}</div>
        )}
      </ul>

      <button
        onClick={handleClickContinue}
        className="step-button"
        disabled={Object.keys(errors).length > 0}
      >
        Continue
      </button>
	  <div className="step-bottom">
        <img src={iconLocked} alt="Secure form" /> <span>Secure form</span>
      </div>
    </>
  );
}

export default FormStep1;
