import {
  TAKE_CASH_ERROR_MESSAGE,
  TAKE_CASH_OPTIONS,
} from '../../../lib/constants';
import iconLocked from '../../../assets/img/icon-locked.webp';
import tooltipImage from '../../../assets/img/tooltip.webp';
import { useFormContext } from 'react-hook-form';
import { useFormDataContext } from '../../../lib/contexts';
import { useRef } from 'react';
import { useScrollToTop } from '../../../lib/hooks';
function FormStep4() {
  const { register, setValue, trigger, handleSubmit, formState, clearErrors } =
    useFormContext();
  const { updateStep, updateFormData, formData } = useFormDataContext();
  const { errors } = formState;
  const takecashRef = useRef();

  const handleClick = (event) => {
    event.stopPropagation();
    if (event.target.type !== 'radio') {
      const input = event.currentTarget.querySelector('input[type="radio"]');
      input.click();
    }
  };

  const handleClickContinue = handleSubmit(async (_data) => {
    const isValid = await trigger(['takecash']);

    if (!isValid) {
      const [firstErrorKey] = Object.keys(errors);
      const errorRefs = {
        takecash: takecashRef,
      };

      errorRefs[firstErrorKey]?.current?.focus();
    } else {
      updateStep(formData.step + 1);
    }
  });
  useScrollToTop();

  return (
    <>
      <a
        onClick={() => {
          clearErrors();
          updateStep(formData.step - 1);
        }}
        className="step-back"
        href
      >
        ← Back
      </a>
      <h3 className="step-title">
        <span>Would you like to take cash out?</span>
        <a href="#" className="tooltip">
          <span className="tooltip-icon">
            <img src={tooltipImage} alt="tooltipImage" />
          </span>
          <span className="tooltip-content">
            <span className="arrow-wrap">
              <span className="arrow"></span>
            </span>
            Use your home equity to pull cash out to pay off credit card bills,
            home improvement project, etc{' '}
          </span>
        </a>
      </h3>
      <ul className="radio-list">
        {TAKE_CASH_OPTIONS.map(
          ({ id, value, textOption, registerLabel }, index) => (
            <li
              key={`${id}-${index}`}
              className={`radio-list-item ${
                errors[registerLabel] && 'form-step__error'
              }`}
              onClick={handleClick}
            >
              <label className="radio-button">
                <input
                  type="radio"
                  value={value}
                  id={id}
                  ref={takecashRef}
                  {...register(registerLabel, { required: true })}
                  onChange={(e) => {
                    updateFormData({ takecash: e.target.value });
                    setValue(registerLabel, e.target.value);
                  }}
                />
                <span htmlFor={id}>{textOption}</span>
                <div className="radiobutton"></div>
              </label>
            </li>
          )
        )}
        {errors.creditrating && (
          <div className="form-step__error-message">
            {TAKE_CASH_ERROR_MESSAGE}
          </div>
        )}
      </ul>

      <button
        onClick={handleClickContinue}
        className="step-button"
        disabled={Object.keys(errors).length > 0}
      >
        Continue
      </button>
      <div className="step-bottom">
        <img src={iconLocked} alt="Secure form" /> <span>Secure form</span>
      </div>
    </>
  );
}

export default FormStep4;
