import './topBarAndFooter.css';

import GovLoanLogo from '../../assets/img/logo.webp';

function TopBarAndFooter({ className = '' }) {
  return (
    <div id="site-header">
      <div className="container">
        <div className="row">
          <a href="/" className="logo">
            <img src={GovLoanLogo} alt="Logo" className="image" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default TopBarAndFooter;
