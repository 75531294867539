import {
  AppProvider,
  DisclaimerProvider,
  FormDataProvider,
  LenderProvider,
  LocationProvider,
  ZipProvider,
} from './';

import { HelmetProvider } from 'react-helmet-async';

const CombinedProviders = ({ children }) => (
  <HelmetProvider>
    <AppProvider>
      <ZipProvider>
        <LenderProvider>
          <LocationProvider>
            <FormDataProvider>
              <DisclaimerProvider>{children}</DisclaimerProvider>
            </FormDataProvider>
          </LocationProvider>
        </LenderProvider>
      </ZipProvider>
    </AppProvider>
  </HelmetProvider>
);

export default CombinedProviders;
