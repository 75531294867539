import './thankYouAlternative.css';

import { useConditionalNavigation, useScrollToTop } from '../../lib/hooks';

import GovLoanLogo from '../../assets/img/logo-popup.webp';
import parse from 'html-react-parser';
import { useLendersContext } from '../../lib/contexts';
import { useNavigate } from 'react-router-dom';

function ThankYouAlternative() {
  const { name, pixel } = useLendersContext();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  useConditionalNavigation(!name, '/');
  useScrollToTop();
  const parsedPixel = parse(pixel || '');

  return (
    <div className="thank-you-alternative__container">
      {pixel && (
        <div className="thank-you-alternative-lenders__pixel">
          {parsedPixel}
        </div>
      )}
      <div className="thank-you-alternative__box thank-you-alternative__main-box">
        <div className="thank-you-alternative__logo-container">
          <img
            src={GovLoanLogo}
            alt="Logo"
            className="thank-you-alternative__logo"
          />
        </div>

        <div className="thank-you-alternative__main-content">
          <div className="thank-you-alternative__main-content-title">
            Thank You
          </div>
          <div className="thank-you-alternative__main-content-text">
            Thank You for your request {name}!
          </div>
          <div className="thank-you-alternative__main-content-text">
            Stay tuned as we match you with top-notch lenders who will exceed
            your expectations and deliver outstanding results.
          </div>
        </div>
      </div>
      <div className="thank-you-alternative__box thank-you-alternative__phone-box">
        <div className="thank-you-alternative__main-content-title">
          Our team is working diligently to find the best bath contractor for
          your needs.
        </div>
        <div className="thank-you-alternative__main-content-text">
          We appreciate your trust in us, and we&apos;re committed to helping
          you achieve your goal!
        </div>
      </div>
      <div className="thank-you-alternative__box thank-you-alternative__home-box">
        <div className="thank-you-alternative__main-content-title">
          Ready for more options?
        </div>
        <div className="thank-you-alternative__main-content-text">
          Return to our main form and discover even more project opportunities.
        </div>
        <button
          type="button"
          className="thank-you-alternative__main-content-btn"
          onClick={handleClick}
        >
          Explore More Choices
        </button>
      </div>
    </div>
  );
}

export default ThankYouAlternative;
