import {
  ADDRESS_ERROR_MESSAGE,
  ADDRESS_PATTERN_ERROR_MESSAGE,
  ADDRESS_REGEX,
  PHONE_ERROR_MESSAGE,
  US_PHONE_REGEX,
} from '../../../lib/constants';
import {
  CustomInput,
  CustomPhoneInput,
  CustomSubmit,
} from '../../CustomComponents';
import { useFormContext } from 'react-hook-form';
import { useFormDataContext } from '../../../lib/contexts';
import { useScrollToTop } from '../../../lib/hooks';
import { validatePhoneNumber } from '../../../lib/utils';

function FormStep9() {
  const { register, clearErrors } = useFormContext();

  const { updateStep, updateFormData, formData } = useFormDataContext();
  useScrollToTop();

  return (
    <>
      <a
        onClick={() => {
          clearErrors();
          updateStep(formData.step - 1);
        }}
        className="step-back"
        href
      >
        ← Back
      </a>
      <div className="form-header">
        <h3>Your address information</h3>
      </div>
      <div className="input-wrap">
        <label>Street Address</label>
        <CustomInput
          type="text"
          name="address"
          register={register}
          onInputChange={updateFormData}
          validation={{
            required: ADDRESS_ERROR_MESSAGE,
            pattern: {
              value: ADDRESS_REGEX,
              message: ADDRESS_PATTERN_ERROR_MESSAGE,
            },
          }}
        />
        <span className="street-info">
          {formData.city}, {formData.state}, {formData.zip}
        </span>
      </div>
      <div className="input-wrap">
        <label>Phone Number</label>
        <CustomPhoneInput
          name="phone"
          placeholder="Phone (+1 is not used)"
          mask={US_PHONE_REGEX}
          autoComplete="off"
          validation={{
            required: PHONE_ERROR_MESSAGE,
            validate: validatePhoneNumber,
          }}
        />
      </div>
      <CustomSubmit />
    </>
  );
}

export default FormStep9;
