import { createContext, useContext, useEffect, useState } from 'react';

import { getDisclaimerContent } from '../api';

const defaultState = {
  privacyPolicy: '',
  termsOfUse: '',
  partnerList: '',
};

const DisclaimerContext = createContext();

export const useDisclaimerContext = () => useContext(DisclaimerContext);

export const DisclaimerProvider = ({ children }) => {
  const [disclaimerContext, setDisclaimerContext] = useState(defaultState);

  useEffect(() => {
    getDisclaimerContent()
      .then((data) => {
        setDisclaimerContext((prevState) => ({
          ...prevState,
          ...data,
        }));
      })
      .catch((error) => {
        console.error('Error fetching API data:', error);
      });
  }, []);

  return (
    <DisclaimerContext.Provider
      value={{ disclaimerContext, setDisclaimerContext }}
    >
      {children}
    </DisclaimerContext.Provider>
  );
};
