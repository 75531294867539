import {
  ESTIMATED_MORTGAGE,
  ESTIMATED_MORTGAGE_ERROR_MESSAGE,
  ESTIMATED_PROPERTY,
  ESTIMATED_PROPERTY_ERROR_MESSAGE,
} from '../../../lib/constants';
import { CustomSelect } from '../../CustomComponents';
import iconLocked from '../../../assets/img/icon-locked.webp';
import { useFormContext } from 'react-hook-form';
import { useFormDataContext } from '../../../lib/contexts';
import { useRef } from 'react';
import { useScrollToTop } from '../../../lib/hooks';

function FormStep2() {
  const { register, trigger, handleSubmit, formState, clearErrors } =
    useFormContext();
  const { updateStep, updateFormData, formData } = useFormDataContext();
  const { errors } = formState;
  const estimatedPropertyRef = useRef();
  const estimatedMortgageRef = useRef();

  const handleClickContinue = handleSubmit(async (_data) => {
    const isValid = await trigger(['estimatedMortgage', 'estimatedProperty']);

    if (!isValid) {
      const [firstErrorKey] = Object.keys(errors);
      const errorRefs = {
        estimatedMortgage: estimatedMortgageRef,
        estimatedProperty: estimatedPropertyRef,
      };

      errorRefs[firstErrorKey]?.current?.focus();
    } else {
      updateStep(formData.step + 1);
    }
  });

  useScrollToTop();
  return (
    <>
      <a
        onClick={() => {
          clearErrors();
          updateStep(formData.step - 1);
        }}
        className="step-back"
        href
      >
        ← Back
      </a>
      <div className="step active" data-step>
        <h3 className="step-title">Estimated Property Value:</h3>
        <div className="select-wrap">
          <CustomSelect
            options={ESTIMATED_PROPERTY}
            name="estimatedProperty"
            value={formData.estimatedProperty}
            register={register}
            onChange={updateFormData}
            errorMessage={ESTIMATED_PROPERTY_ERROR_MESSAGE}
            errors={errors}
            customRef={estimatedPropertyRef}
          />
        </div>
        <div className="input-space"></div>
        <h3 className="step-title">Estimated Mortgage Balance:</h3>
        <div className="select-wrap">
          <CustomSelect
            options={ESTIMATED_MORTGAGE}
            name="estimatedMortgage"
            value={formData.estimatedMortgage}
            register={register}
            onChange={updateFormData}
            errorMessage={ESTIMATED_MORTGAGE_ERROR_MESSAGE}
            errors={errors}
            customRef={estimatedMortgageRef}
          />
        </div>
        <button
          onClick={handleClickContinue}
          className="step-button"
          disabled={Object.keys(errors).length > 0}
        >
          Continue
        </button>
        <div className="step-bottom">
          <img src={iconLocked} alt="Secure form" /> <span>Secure form</span>
        </div>
      </div>
    </>
  );
}

export default FormStep2;
