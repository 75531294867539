import './disclaimer.css';

import { CustomModalContent } from '../CustomComponents';
import { useDisclaimerContext } from '../../lib/contexts';
import { useState } from 'react';

function SecondFormDisclaimer() {
  const { disclaimerContext } = useDisclaimerContext();
  const { privacyPolicy, termsOfUse } = disclaimerContext;

  const [isOpen, setIsOpen] = useState({
    privacyPolicy: false,
    optOut: false,
  });
  const openModal = (key) => setIsOpen({ ...isOpen, [key]: true });
  const closeModal = (key) => setIsOpen({ ...isOpen, [key]: false });

  /*const date = new Date().getDate();
  const month = new Date().toLocaleString('default', { month: 'long' });*/

  return (
    <div className="disclaimer__container">
      <CustomModalContent
        title="Privacy Policy"
        isOpen={isOpen.privacyPolicy}
        onClose={() => closeModal('privacyPolicy')}
        content={privacyPolicy.data?.html}
        isHtml
      />
      <CustomModalContent
        title="Terms Of Use"
        isOpen={isOpen.termsOfUse}
        onClose={() => closeModal('termsOfUse')}
        content={termsOfUse.data?.html}
        isHtml
      />

      <>
        <ul className="footer-links">
          <li>
            <a
              className="disclaimer__button-as-link"
              onClick={() => openModal('privacyPolicy')}
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              className="disclaimer__button-as-link"
              onClick={() => openModal('termsOfUse')}
            >
              Terms of Use
            </a>
          </li>
          <li>
            <a
              className="disclaimer__button-as-link"
              onClick={() =>
                window.open(process.env.REACT_APP_DO_NOT_SELL_MY_INFO, '_blank')
              }
            >
              Do Not Sell My Info
            </a>
          </li>
          <li>
            <a
              className="disclaimer__button-as-link"
              onClick={() =>
                window.open(process.env.REACT_APP_OPT_OUT, '_blank')
              }
            >
              Opt Out
            </a>
          </li>
        </ul>
      </>
    </div>
  );
}

export default SecondFormDisclaimer;
