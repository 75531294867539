import { FormProvider, useForm } from 'react-hook-form';
import { useFormDataContext, useLocation } from '../../../lib/contexts';
import FormZip from '../steps/FormZip';
import React from 'react';
function FirstSection() {
  const { formData } = useFormDataContext();
  const { location } = useLocation();
  const methods = useForm({ defaultValues: formData, mode: 'onSubmit' });
  const year = new Date().getFullYear();
  const date = new Date().getDate();
  const month = new Date().toLocaleString('default', { month: 'long' });
  return (
    <section className="hero">
      <div className="container">
        <div className="row">
          <div className="inner-wrap">
            <div className="inner">
              <div className="text">
                <h1 className="">
                    {location} Update: {month} {date}, {year}
                </h1>
                <p className="fedText">
                  <strong>FED ANNOUNCES 3 RATE CUTS IN {year}!*</strong>
                </p>
				<h1 className="" id="subheader">
                    TAKE CASH OUT & POTENTIALLY LOWER YOUR MONTHLY PAYMENTS
                </h1>
                <p className="">
                  <strong>TAKE 60 SECOND QUIZ TO CALCULATE SAVINGS!</strong>
                </p>
                <FormProvider {...methods}>
                  <FormZip />
                </FormProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FirstSection;
