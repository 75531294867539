export const HOMETYPE_OPTIONS = [
  {
    id: 'homeTypeSingleFamily',
    value: 'Single Family',
    textOption: 'Single Family Home',
    registerLabel: 'hometype',
  },
  {
    id: 'homeTypeMultiFamily',
    value: 'Multi-Family Dwelling Unit',
    textOption: 'Multi-Family Home',
    registerLabel: 'hometype',
  },
  {
    id: 'homeTypeCondominium',
    value: 'Condominium',
    textOption: 'Condominium',
    registerLabel: 'hometype',
  },
  {
    id: 'homeTypeMobileHome',
    value: 'Mobile Home',
    textOption: 'Mobile Home',
    registerLabel: 'hometype',
  },
];

export const ESTIMATED_PROPERTY = [
  { value: '90000', text: '$75,000 - $90,000' },
  { value: '105000', text: '$90,001 - $105,000' },
  { value: '120000', text: '$105,001 - $120,000' },
  { value: '135000', text: '$120,001 - $135,000' },
  { value: '150000', text: '$135,001 - $150,000' },
  { value: '165000', text: '$150,001 - $165,000' },
  { value: '180000', text: '$165,001 - $180,000' },
  { value: '195000', text: '$180,001 - $195,000' },
  { value: '210000', text: '$195,001 - $210,000' },
  { value: '225000', text: '$210,001 - $225,000' },
  { value: '240000', text: '$225,001 - $240,000' },
  { value: '255000', text: '$240,001 - $255,000' },
  { value: '270000', text: '$255,001 - $270,000' },
  { value: '285000', text: '$270,001 - $285,000' },
  { value: '300000', text: '$285,001 - $300,000' },
  { value: '315000', text: '$300,001 - $315,000' },
  { value: '330000', text: '$315,001 - $330,000' },
  { value: '345000', text: '$330,001 - $345,000' },
  { value: '360000', text: '$345,001 - $360,000' },
  { value: '375000', text: '$360,001 - $375,000' },
  { value: '390000', text: '$375,001 - $390,000' },
  { value: '405000', text: '$390,001 - $405,000' },
  { value: '420000', text: '$405,001 - $420,000' },
  { value: '435000', text: '$420,001 - $435,000' },
  { value: '450000', text: '$435,001 - $450,000' },
  { value: '465000', text: '$450,001 - $465,000' },
  { value: '480000', text: '$465,001 - $480,000' },
  { value: '495000', text: '$480,001 - $495,000' },
  { value: '510000', text: '$495,001 - $510,000' },
  { value: '525000', text: '$510,001 - $525,000' },
  { value: '540000', text: '$525,001 - $540,000' },
  { value: '555000', text: '$540,001 - $555,000' },
  { value: '570000', text: '$555,001 - $570,000' },
  { value: '585000', text: '$570,001 - $585,000' },
  { value: '600000', text: '$585,001 - $600,000' },
  { value: '615000', text: '$600,001 - $615,000' },
  { value: '630000', text: '$615,001 - $630,000' },
  { value: '645000', text: '$630,001 - $645,000' },
  { value: '660000', text: '$645,001 - $660,000' },
  { value: '675000', text: '$660,001 - $675,000' },
  { value: '690000', text: '$675,001 - $690,000' },
  { value: '705000', text: '$690,001 - $705,000' },
  { value: '720000', text: '$705,001 - $720,000' },
  { value: '735000', text: '$720,001 - $735,000' },
  { value: '750000', text: '$735,001 - $750,000' },
  { value: '765000', text: '$750,001 - $765,000' },
  { value: '780000', text: '$765,001 - $780,000' },
  { value: '795000', text: '$780,001 - $795,000' },
  { value: '810000', text: '$795,001 - $810,000' },
  { value: '825000', text: '$810,001 - $825,000' },
  { value: '840000', text: '$825,001 - $840,000' },
  { value: '855000', text: '$840,001 - $855,000' },
  { value: '870000', text: '$855,001 - $870,000' },
  { value: '885000', text: '$870,001 - $885,000' },
  { value: '900000', text: '$885,001 - $900,000' },
  { value: '915000', text: '$900,001 - $915,000' },
  { value: '930000', text: '$915,001 - $930,000' },
  { value: '945000', text: '$930,001 - $945,000' },
  { value: '960000', text: '$945,001 - $960,000' },
  { value: '975000', text: '$960,001 - $975,000' },
  { value: '990000', text: '$975,001 - $990,000' },
  { value: '1000000', text: '$990,001 - $1,000,000' },
  { value: '1100000', text: '$1.0M - $1.1M' },
  { value: '1200000', text: '$1.11M - $1.2M' },
  { value: '1300000', text: '$1.21M - $1.3M' },
  { value: '1400000', text: '$1.31M - $1.4M' },
  { value: '1500000', text: '$1.41M - $1.5M' },
  { value: '1600000', text: '$1.51M - $1.6M' },
  { value: '1700000', text: '$1.61M - $1.7M' },
  { value: '1800000', text: '$1.71M - $1.8M' },
  { value: '1900000', text: '$1.81M - $1.9M' },
  { value: '2000000', text: '$1.91M - $2.0M' },
  { value: '2000001', text: '$2.0M or more' },
];

export const ESTIMATED_MORTGAGE = [
  { value: '59900', text: '$50,000 - $59,999' },
  { value: '75000', text: '$60,000 - $79,999' },
  { value: '85000', text: '$80,000 - $89,999' },
  { value: '95000', text: '$90,000 - $99,999' },
  { value: '120000', text: '$100,000 - $120,000' },
  { value: '135000', text: '$120,001 - $135,000' },
  { value: '150000', text: '$135,001 - $150,000' },
  { value: '165000', text: '$150,001 - $165,000' },
  { value: '180000', text: '$165,001 - $180,000' },
  { value: '195000', text: '$180,001 - $195,000' },
  { value: '210000', text: '$195,001 - $210,000' },
  { value: '225000', text: '$210,001 - $225,000' },
  { value: '240000', text: '$225,001 - $240,000' },
  { value: '255000', text: '$240,001 - $255,000' },
  { value: '270000', text: '$255,001 - $270,000' },
  { value: '285000', text: '$270,001 - $285,000' },
  { value: '300000', text: '$285,001 - $300,000' },
  { value: '315000', text: '$300,001 - $315,000' },
  { value: '330000', text: '$315,001 - $330,000' },
  { value: '345000', text: '$330,001 - $345,000' },
  { value: '360000', text: '$345,001 - $360,000' },
  { value: '375000', text: '$360,001 - $375,000' },
  { value: '390000', text: '$375,001 - $390,000' },
  { value: '405000', text: '$390,001 - $405,000' },
  { value: '420000', text: '$405,001 - $420,000' },
  { value: '435000', text: '$420,001 - $435,000' },
  { value: '450000', text: '$435,001 - $450,000' },
  { value: '465000', text: '$450,001 - $465,000' },
  { value: '480000', text: '$465,001 - $480,000' },
  { value: '495000', text: '$480,001 - $495,000' },
  { value: '510000', text: '$495,001 - $510,000' },
  { value: '525000', text: '$510,001 - $525,000' },
  { value: '540000', text: '$525,001 - $540,000' },
  { value: '555000', text: '$540,001 - $555,000' },
  { value: '570000', text: '$555,001 - $570,000' },
  { value: '585000', text: '$570,001 - $585,000' },
  { value: '600000', text: '$585,001 - $600,000' },
  { value: '615000', text: '$600,001 - $615,000' },
  { value: '630000', text: '$615,001 - $630,000' },
  { value: '645000', text: '$630,001 - $645,000' },
  { value: '660000', text: '$645,001 - $660,000' },
  { value: '675000', text: '$660,001 - $675,000' },
  { value: '690000', text: '$675,001 - $690,000' },
  { value: '705000', text: '$690,001 - $705,000' },
  { value: '720000', text: '$705,001 - $720,000' },
  { value: '735000', text: '$720,001 - $735,000' },
  { value: '750000', text: '$735,001 - $750,000' },
  { value: '765000', text: '$750,001 - $765,000' },
  { value: '780000', text: '$765,001 - $780,000' },
  { value: '795000', text: '$780,001 - $795,000' },
  { value: '810000', text: '$795,001 - $810,000' },
  { value: '825000', text: '$810,001 - $825,000' },
  { value: '840000', text: '$825,001 - $840,000' },
  { value: '855000', text: '$840,001 - $855,000' },
  { value: '870000', text: '$855,001 - $870,000' },
  { value: '885000', text: '$870,001 - $885,000' },
  { value: '900000', text: '$885,001 - $900,000' },
  { value: '915000', text: '$900,001 - $915,000' },
  { value: '930000', text: '$915,001 - $930,000' },
  { value: '945000', text: '$930,001 - $945,000' },
  { value: '960000', text: '$945,001 - $960,000' },
  { value: '975000', text: '$960,001 - $975,000' },
  { value: '990000', text: '$975,001 - $990,000' },
  { value: '1000000', text: '$990,001 - $1,000,000' },
  { value: '1100000', text: '$1.0M - $1.1M' },
  { value: '1200000', text: '$1.11M - $1.2M' },
  { value: '1300000', text: '$1.21M - $1.3M' },
  { value: '1400000', text: '$1.31M - $1.4M' },
  { value: '1500000', text: '$1.41M - $1.5M' },
  { value: '1600000', text: '$1.51M - $1.6M' },
  { value: '1700000', text: '$1.61M - $1.7M' },
  { value: '1800000', text: '$1.71M - $1.8M' },
  { value: '1900000', text: '$1.81M - $1.9M' },
  { value: '2000000', text: '$1.91M - $2.0M' },
  { value: '2000001', text: '$2.0M or more' },
];

export const CREDIT_RATING_OPTIONS = [
  {
    id: 'excellent',
    value: 'Excellent',
    textOption: 'Excellent (701+)',
    registerLabel: 'creditType',
  },
  {
    id: 'good',
    value: 'Good',
    textOption: 'Good (640-700)',
    registerLabel: 'creditType',
  },
  {
    id: 'fair',
    value: 'Fair',
    textOption: 'Fair (560-639)',
    registerLabel: 'creditType',
  },
  {
    id: 'poor',
    value: 'Poor',
    textOption: 'Poor (559 or less)',
    registerLabel: 'creditType',
  },
];
export const TAKE_CASH_OPTIONS = [
  {
    id: 'yes',
    value: 'yes',
    textOption: 'Yes',
    registerLabel: 'takecash',
  },
  {
    id: 'no',
    value: 'no',
    textOption: 'no',
    registerLabel: 'takecash',
  },
  {
    id: 'maybe',
    value: 'maybe',
    textOption: 'Maybe',
    registerLabel: 'takecash',
  },
];
export const EMPLOYMENT_STATUS_OPTIONS = [
  {
    id: 'employed',
    value: 'employed',
    textOption: 'Employed',
    registerLabel: 'employmentstatus',
  },
  {
    id: 'selfEmployed',
    value: 'self-employed',
    textOption: 'Self-Employed',
    registerLabel: 'employmentstatus',
  },
  {
    id: 'retired',
    value: 'retired',
    textOption: 'Retired',
    registerLabel: 'employmentstatus',
  },
  {
    id: 'other',
    value: 'other',
    textOption: 'Other',
    registerLabel: 'employmentstatus',
  },
];
export const FHA_LOAN_OPTIONS = [
  {
    id: 'no',
    value: 'no',
    textOption: 'No',
    registerLabel: 'fhaloan',
  },
  {
    id: 'yes',
    value: 'yes',
    textOption: 'Yes',
    registerLabel: 'fhaloan',
  },
];
export const VETERAN_OPTIONS = [
  {
    id: 'no',
    value: 'no',
    textOption: 'No',
    registerLabel: 'veteran',
  },
  {
    id: 'yes',
    value: 'yes',
    textOption: 'Yes',
    registerLabel: 'veteran',
  },
];
export const STATES_OPTIONS = [
  { text: 'AL', value: 'AL' },
  { text: 'AK', value: 'AK' },
  { text: 'AZ', value: 'AZ' },
  { text: 'AR', value: 'AR' },
  { text: 'CA', value: 'CA' },
  { text: 'CO', value: 'CO' },
  { text: 'CT', value: 'CT' },
  { text: 'DE', value: 'DE' },
  { text: 'FL', value: 'FL' },
  { text: 'GA', value: 'GA' },
  { text: 'HI', value: 'HI' },
  { text: 'ID', value: 'ID' },
  { text: 'IL', value: 'IL' },
  { text: 'IN', value: 'IN' },
  { text: 'IA', value: 'IA' },
  { text: 'KS', value: 'KS' },
  { text: 'KY', value: 'KY' },
  { text: 'LA', value: 'LA' },
  { text: 'ME', value: 'ME' },
  { text: 'MD', value: 'MD' },
  { text: 'MA', value: 'MA' },
  { text: 'MI', value: 'MI' },
  { text: 'MN', value: 'MN' },
  { text: 'MS', value: 'MS' },
  { text: 'MO', value: 'MO' },
  { text: 'MT', value: 'MT' },
  { text: 'NE', value: 'NE' },
  { text: 'NV', value: 'NV' },
  { text: 'NH', value: 'NH' },
  { text: 'NJ', value: 'NJ' },
  { text: 'NM', value: 'NM' },
  { text: 'NY', value: 'NY' },
  { text: 'NC', value: 'NC' },
  { text: 'ND', value: 'ND' },
  { text: 'OH', value: 'OH' },
  { text: 'OK', value: 'OK' },
  { text: 'OR', value: 'OR' },
  { text: 'PA', value: 'PA' },
  { text: 'RI', value: 'RI' },
  { text: 'SC', value: 'SC' },
  { text: 'SD', value: 'SD' },
  { text: 'TN', value: 'TN' },
  { text: 'TX', value: 'TX' },
  { text: 'UT', value: 'UT' },
  { text: 'VT', value: 'VT' },
  { text: 'VA', value: 'VA' },
  { text: 'WA', value: 'WA' },
  { text: 'WV', value: 'WV' },
  { text: 'WI', value: 'WI' },
  { text: 'WY', value: 'WY' },
];
