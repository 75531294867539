export const ZIP_CODE_REGEX = /^[0-9]{5}$/i;

export const NAME_REGEX = /^[a-zA-Z\s'-.]+$/;

export const ADDRESS_REGEX = /^[^@]*$/;

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@([A-Z0-9][A-Z0-9.]*\.[A-Z]{2,4})$/i;

export const US_PHONE_REGEX = [
  '(',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ')',
  ' ',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
